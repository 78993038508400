<template>
  <div class="card-wrapper ">
    <CardFram
      :class="{ 'card-flip': flipCard, 'card-overlay': !firstCardDone }"
    >
      <template #header>{{ $t("card.title") }}</template>
      <template #body>
        <form>
          <formWrapper :validator="$v.form">
            <section class="companies-wrapper">
              <b-row>
                <template v-if="!items.length">
                  <b-col
                    class="mb-2"
                    md="4"
                    v-for="shimmer in shimmers"
                    :key="shimmer.index"
                  >
                    <content-placeholders :rounded="true">
                      <content-placeholders-img /> </content-placeholders
                  ></b-col>
                </template>
                <template v-else>
                  <b-col
                    cols="6"
                    sm="6"
                    md="4"
                    v-for="item in items"
                    :key="item.index"
                  >
                    <div
                      class="img-wrapper"
                      @click="handleSelectedCompany(item)"
                    >
                      <b-img :src="getImgSrc(item)" :alt="item.name"></b-img>
                    </div>
                  </b-col>
                </template>
              </b-row>
            </section>
            <b-col
              cols="12"
              v-for="(formKey, index) in formKeys"
              :key="index"
              class="input-wrapper pa-0 input-margin"
            >
              <form-group :name="formKey" :attribute="`input.${formKey}`">
                <b-form-input
                  slot-scope="{ attrs, listeners }"
                  v-on="listeners"
                  v-bind="attrs"
                  v-model="form[formKey]"
                  class="second-card-input "
                  v-inputFocus="{
                    index,
                    className: 'second-card-span',
                    formKey,
                    form
                  }"
                ></b-form-input>
              </form-group>
              <span
                v-label-focus="{ index, className: 'second-card-input' }"
                class="label-default second-card-span font-light"
              >
                {{ $t(`input.${formKey}`) }}
              </span>
            </b-col>
          </formWrapper>
        </form>
      </template>
      <template #footer>
        <b-col cols="12">
          <b-button
            @click="handleNextCard"
            :disabled="$v.form.$invalid"
            class="nextButton font-bold"
            variant="default"
            >{{ changeTextButton }}</b-button
          >
        </b-col>
      </template>
    </CardFram>

    <!-- back card -->
    <CardFram class="card-back" :class="{ 'card-flip-back': flipCard }">
      <template #back-header>
        <b-col cols="12">
          <b-img
            class="checked-img"
            :src="require('@/assets/imgs/valideCard.svg')"
          ></b-img>
        </b-col>
      </template>
      <template #body>
        <b-row>
          <section class="companies-wrapper">
            <b-row>
              <b-col
                cols="6"
                sm="6"
                md="4"
                v-for="item in activeCompanies"
                :key="item.index"
              >
                <div class="img-wrapper">
                  <b-img
                    :class="{ 'active-img': item.selected }"
                    :src="item.logo && item.logo.path"
                    :alt="item.name"
                  ></b-img>
                </div>
              </b-col>
            </b-row>
          </section>
          <b-col cols="12" class="my-5">
            <b-list-group>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
                v-for="(itemKey, index) in formKeys"
                :key="index"
              >
                <span class="font-regular">{{ $t(`input.${itemKey}`) }}</span>
                <span class="font-regular">{{ form[itemKey] }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </template>
      <template #footer>
        <b-col cols="12" class="text-sm-center">
          <b-button
            @click="flipCard = !flipCard"
            class="nextButton edit-button font-bold "
            variant="default"
            >{{ $t("button.edit") }}</b-button
          >
        </b-col>
      </template>
    </CardFram>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import { IndexData } from "@/helpers/apiMethods";

export default {
  name: "SecondCard",
  props: {
    firstCardDone: {
      type: Boolean,
      default: false
    },
    showOverLay: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CardFram: () => import("@/components/CardFram")
  },
  data() {
    return {
      form: {
        investment_value: ""
      },
      flipCard: false,
      selectedCompany: [],
      activeCompanies: [],
      items: [],
      isEdit: false,
      shimmers: 8
    };
  },
  methods: {
    resetForm() {
      this.form = {
        investment_value: ""
      };
      this.selectedCompany = [];
      this.isEdit = false;
      this.$store.dispatch("ClearServerErrors");
    },
    handleNextCard() {
      this.flipCard = !this.flipCard;
      this.isEdit = true;
      const formData = { ...this.form, companies_ids: this.selectedCompany };
      this.$emit("handle_next_card", "secondCardDone");
      this.$emit("second_card_data", formData);
    },
    getImgSrc(item) {
      if (!item.selected) {
        return item.grey_logo && item.grey_logo.path;
      } else {
        return item.logo && item.logo.path;
      }
    },
    handleSelectedCompany(item) {
      item.selected = !item.selected;
      if (item.selected) {
        this.activeCompanies.push(item);
        this.selectedCompany.push(item.id);
      } else {
        this.selectedCompany.splice(item.index, 1);
        this.activeCompanies.splice(item.index, 1);
      }
    },
    handleGetCompanies() {
      if (this.showOverLay) {
        IndexData({ reqName: "companies" })
          .then(res => {
            const { companies } = res.data;

            this.items = companies.map(el => {
              return {
                ...el,
                selected: false
              };
            });
            console.log(this.items);
          })
          .catch(err => console.log(err));
      }
    }
  },
  computed: {
    formKeys() {
      return Object.keys(this.form);
    },
    changeTextButton() {
      if (this.isEdit) {
        return this.$t("button.save");
      } else {
        return this.$t("button.next");
      }
    }
    // checkCompanies() {
    //   if (this.selectedCompany.length > 0) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }
  },
  watch: {
    showOverLay: {
      handler(value) {
        if (value) {
          this.handleGetCompanies();
        } else {
          this.resetForm();
        }
      },
      immediate: true
    }
  },
  validations: {
    form: {
      investment_value: {
        required,
        // numeric,
        minValue: minValue(20000)
      }
    }
  }
};
</script>
